import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../api/API';

import UserItem from '../items/UserItem';
import LoadingItem from '../items/LoadingItem';
import PaginationItem from '../items/PaginationItem';

import ViewUser from './user/ViewUser';
import ChangePasswordUser from './user/ChangePasswordUser';

class ManagerUser extends Component {

    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            loading: true,
            users: [],
            page: 0,
            pages: 0,
            pagination: 0,
            currentUser: null
        }

        this.viewUserRef = React.createRef();
        this.changePasswordUserRef = React.createRef();
        this.paginationRef = React.createRef();

        this.loadUser = this.loadUser.bind(this);
        this.viewUser = this.viewUser.bind(this);
        this.changePasswordUser = this.changePasswordUser.bind(this);
        this.loadPage = this.loadPage.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadUser();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadPage(page) {
        this.loadUser({
            page: page
        })
    }

    loadUser(data) {
        this.setState({
            loading: true,
            users: []
        })

        API.getUser(data).then(res => {
            let users = res.data.data.users;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    users: users,
                    page: res.data.data.page,
                    pages: res.data.data.pages,
                    pagination: res.data.data.pagination
                })
                this.paginationRef.current.initState();
            }
        }).catch(error => {
            this.setState({
                loading: false,
                users: []
            })
        })
    }

    viewUser(user) {
        this.setState({
            currentUser: user
        }, () => {
            window['$']("#viewModal").modal("show");
        })
    }

    changePasswordUser(user) {
        this.setState({
            currentUser: user
        }, () => {
            window['$']("#changePasswordModal").modal("show");
        })
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Người dùng</h1>
                </div>
                <br></br>

                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tên đăng nhập</th>
                                <th>Họ và tên</th>
                                <th>Email</th>
                                <th>Số điện thoại</th>
                                <th>Đăng nhập cuối</th>
                                <th>Thời gian tạo</th>
                                <th>Thời gian sửa</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.users.map((user, index) => (
                                    <UserItem index={index} key={index} data={user} page={this.state.page} pagination={this.state.pagination} viewUser={this.viewUser} changePasswordUser={this.changePasswordUser} />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {this.state.loading ? <LoadingItem /> : null}
                {this.state.loading ? null : <PaginationItem ref={this.paginationRef} page={this.state.page} pages={this.state.pages} loadPage={this.loadPage} />}

                <ViewUser ref={this.viewUserRef} data={this.state.currentUser} />
                <ChangePasswordUser ref={this.changePasswordUserRef} data={this.state.currentUser} />
            </main >
        )
    }
}

export default withRouter(ManagerUser);