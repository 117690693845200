import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../api/API';

import ConfigItem from '../items/ConfigItem';
import LoadingItem from '../items/LoadingItem';

import AddConfig from './config/AddConfig';
import EditConfig from './config/EditConfig';
import RemoveConfig from './config/RemoveConfig';

class ManagerConfig extends Component {

    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            loading: true,
            configs: [],
            currentConfig: null
        }

        this.addConfigRef = React.createRef();
        this.editConfigRef = React.createRef();
        this.removeConfigRef = React.createRef();
        this.loadConfig = this.loadConfig.bind(this);
        this.editConfig = this.editConfig.bind(this);
        this.removeConfig = this.removeConfig.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadConfig();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadConfig() {
        this.setState({
            loading: true,
            configs: []
        })

        API.getConfig().then(res => {
            let configs = res.data.data.configs;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    configs: configs
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                configs: []
            })
        })
    }

    editConfig(config) {
        this.setState({
            currentConfig: config
        }, () => {
            this.editConfigRef.current.initState();
            window['$']("#editModal").modal("show");
        })
    }

    removeConfig(config) {
        this.setState({
            currentConfig: config
        }, () => {
            window['$']("#removeModal").modal("show");
        })
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Cấu hình</h1>
                </div>
                <button type="button" className="btn btn-success float-right" data-toggle="modal" data-target="#addModal">Thêm mới</button>
                <br></br>
                <br></br>

                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tên</th>
                                <th>Giá trị</th>
                                <th>Ghi chú</th>
                                <th>Thời gian tạo</th>
                                <th>Thời gian sửa</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.configs.map((config, index) => (
                                    <ConfigItem index={index} key={index} data={config} loadConfig={this.loadConfig} editConfig={this.editConfig} removeConfig={this.removeConfig} />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {this.state.loading ? <LoadingItem /> : null}

                <AddConfig ref={this.addConfigRef} loadConfig={this.loadConfig} />
                <EditConfig ref={this.editConfigRef} loadConfig={this.loadConfig} data={this.state.currentConfig} />
                <RemoveConfig ref={this.removeConfigRef} loadConfig={this.loadConfig} data={this.state.currentConfig} />
            </main >
        )
    }
}

export default withRouter(ManagerConfig);