import React, { Component } from 'react';

import API from '../../api/API';

class UploadImageItem extends Component {

    constructor() {
        super();

        this.state = {
            url: '',
            image: ''
        }
        this.onFileChange = this.onFileChange.bind(this);
        this.onUpload = this.onUpload.bind(this);
    }

    onFileChange(e) {
        this.setState({ image: e.target.files[0] })
    }

    onUpload(e) {
        e.preventDefault();
        if (this.state.image) {
            const formData = new FormData();
            formData.append('image', this.state.image);
            API.uploadImage(formData).then(res => {
                this.setState({
                    url: res.data.data.url
                })
            }).catch(error => {
                console.log(error);
            })
        }
    }

    render() {
        return (
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-4">
                        <input type="file" onChange={this.onFileChange} />
                    </div>
                    <div className="col-sm-1">
                        <button className="btn btn-primary" type="submit" onClick={this.onUpload}>Upload</button>
                    </div>
                    <div className="col-sm-7">
                        {this.state.url}
                    </div>
                </div>
            </div>
        )
    }
}

export default UploadImageItem;