import React, { Component } from 'react';

import FormatUtil from '../../../util/FormatUtil';

class ViewUser extends Component {

    render() {
        return (
            <div className="modal fade" id="viewModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Chi tiết người dùng</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">ID:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.id : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Tên đăng nhập:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.username : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Họ và tên:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.name : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Kiểu đăng nhập:</div>
                                        <div className="col-sm-8">{this.props.data ? FormatUtil.getLoginTextByType(this.props.data.type) : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Social ID:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.social_id : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Email:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.email : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Số điện thoại:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.phone : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Ảnh đại diện:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.avatar : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Giới tính:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.gender : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Ngày sinh:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.birth_day : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Trường:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.school : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Lớp:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.class : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Thiết bị:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.device : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Device ID:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.device_id : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Device token:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.device_token : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Thời gian tạo:</div>
                                        <div className="col-sm-8">{this.props.data ? FormatUtil.timeToTimeString(this.props.data.created_at) : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Thời gian sửa:</div>
                                        <div className="col-sm-8">{this.props.data ? FormatUtil.timeToTimeString(this.props.data.updated_at) : ''}</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Hủy</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewUser;