import React, { Component } from 'react';

import FormatUtil from '../../util/FormatUtil';

class LogSubmitItem extends Component {

    constructor() {
        super();
        this.onView = this.onView.bind(this);
        this.onResolve = this.onResolve.bind(this);
        this.onUnResolve = this.onUnResolve.bind(this);
    }

    onView(e) {
        e.preventDefault();
        this.props.viewLogReport(this.props.data);
    }

    onResolve(e) {
        e.preventDefault();
        this.props.updateLogReport(this.props.data, true);
    }

    onUnResolve(e) {
        e.preventDefault();
        this.props.updateLogReport(this.props.data, false);
    }

    render() {
        return (
            <tr>
                <td className="align-middle">{this.props.page * this.props.pagination + this.props.index + 1}</td>
                <td className="align-middle">{this.props.data.question_id}</td>
                <td className="align-middle">{this.props.data.content}</td>
                <td className="align-middle">{this.props.data.is_resolved ? 'Đã sửa' : 'Chưa sửa'}</td>
                <td className="align-middle">{FormatUtil.timeToTimeString(this.props.data.created_at)}</td>
                <td className="align-middle">{FormatUtil.timeToTimeString(this.props.data.updated_at)}</td>
                <td className="align-middle">
                    <div className="btn-toolbar">
                        {
                            this.props.data.is_resolved ? <button type="button" className="btn btn-danger mr-2" onClick={this.onUnResolve}>Chưa sửa</button> : <button type="button" className="btn btn-success mr-2" onClick={this.onResolve}>Sửa</button>
                        }
                        <button type="button" className="btn btn-info mr-2" onClick={this.onView}>Chi tiết</button>
                    </div>
                </td>
            </tr>
        )
    }
}

export default LogSubmitItem;