import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../api/API';

import LogDailyStatisticItem from '../items/LogDailyStatisticItem';
import LoadingItem from '../items/LoadingItem';
import PaginationItem from '../items/PaginationItem';

class ManagerLogDailyStatistic extends Component {

    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            loading: true,
            statistics: [],
            page: 0,
            pages: 0,
            pagination: 0
        }

        this.paginationRef = React.createRef();

        this.loadStatistic = this.loadStatistic.bind(this);
        this.loadPage = this.loadPage.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadStatistic();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadPage(page) {
        this.loadStatistic({
            page: page
        })
    }

    loadStatistic(data) {
        this.setState({
            loading: true,
            statistics: []
        })

        API.getLogDailyStatistics(data).then(res => {
            let statistics = res.data.data.statistics;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    statistics: statistics,
                    page: res.data.data.page,
                    pages: res.data.data.pages,
                    pagination: res.data.data.pagination
                })
                this.paginationRef.current.initState();
            }
        }).catch(error => {
            this.setState({
                loading: false,
                statistics: []
            })
        })
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Thống kê hàng ngày</h1>
                </div>
                <br></br>

                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Thời gian</th>
                                <th>NRU</th>
                                <th>DAU</th>
                                <th>Số lượng Nạp Tiền</th>
                                <th>Tổng Nạp Tiền</th>
                                <th>Số lượng bài thi</th>
                                <th>Số lượng nộp bài</th>
                                <th>Số lượng báo cáo</th>
                                <th>Tổng thời gian</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.statistics.map((statistic, index) => (
                                    <LogDailyStatisticItem index={index} key={index} data={statistic} page={this.state.page} pagination={this.state.pagination}/>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {this.state.loading ? <LoadingItem /> : null}
                {this.state.loading ? null : <PaginationItem ref={this.paginationRef} page={this.state.page} pages={this.state.pages} loadPage={this.loadPage} />}
            </main >
        )
    }
}

export default withRouter(ManagerLogDailyStatistic);