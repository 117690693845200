import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../api/API';

import ExamGroupItem from '../items/ExamGroupItem';
import SelectItem from '../items/SelectItem';
import LoadingItem from '../items/LoadingItem';

import AddExamGroup from './exam_group/AddExamGroup';
import EditExamGroup from './exam_group/EditExamGroup';
import RemoveExamGroup from './exam_group/RemoveExamGroup';

class ManagerExamGroup extends Component {

    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            loading: true,
            classes: [],
            class_id: '',
            subjects: [],
            subject_id: '',
            exam_groups: [],
            currentExamGroup: null
        }

        this.addExamGroupRef = React.createRef();
        this.editExamGroupRef = React.createRef();
        this.removeExamGroupRef = React.createRef();

        this.loadExamGroup = this.loadExamGroup.bind(this);
        this.editExamGroup = this.editExamGroup.bind(this);
        this.removeExamGroup = this.removeExamGroup.bind(this);

        this.onClassSelectChange = this.onClassSelectChange.bind(this);
        this.onSubjectSelectChange = this.onSubjectSelectChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadClass();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadClass() {
        this.setState({
            loading: true,
            class_id: '',
            classes: [],
            subject_id: '',
            subjects: [],
            exam_groups: []
        })

        API.getClass().then(res => {
            let classes = res.data.data.classes;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    classes: classes
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                classes: []
            })
        })
    }

    loadSubject() {
        let data = {
            class_id: this.state.class_id
        }

        this.setState({
            loading: true,
            subject_id: '',
            subjects: [],
            exam_groups: []
        })

        API.getSubject(data).then(res => {
            let subjects = res.data.data.subjects;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    subjects: subjects
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                subjects: []
            })
        })
    }

    loadExamGroup() {
        let data = {
            subject_id: this.state.subject_id
        }

        this.setState({
            loading: true,
            exam_groups: []
        })

        API.getExamGroup(data).then(res => {
            let exam_groups = res.data.data.exam_groups;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    exam_groups: exam_groups
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                exam_groups: []
            })
        })
    }

    onClassSelectChange(id) {
        this.setState({
            class_id: id
        }, () => {
            this.loadSubject();
        })
    }

    onSubjectSelectChange(id) {
        this.setState({
            subject_id: id
        }, () => {
            this.loadExamGroup();
        })
    }

    editExamGroup(itemClass) {
        this.setState({
            currentExamGroup: itemClass
        }, () => {
            this.editExamGroupRef.current.initState();
            window['$']("#editModal").modal("show");
        })
    }

    removeExamGroup(itemClass) {
        this.setState({
            currentExamGroup: itemClass
        }, () => {
            window['$']("#removeModal").modal("show");
        })
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Bộ đề</h1>
                </div>

                <SelectItem data={this.state.classes} default={'Chọn lớp học'} onChange={this.onClassSelectChange} />
                <br></br>
                <SelectItem data={this.state.subjects} default={'Chọn môn học'} onChange={this.onSubjectSelectChange} />
                <br></br>
                {
                    this.state.subject_id.length > 0 ? <button type="button" className="btn btn-success float-right" data-toggle="modal" data-target="#addModal">Thêm mới</button> : null
                }
                <br></br>
                <br></br>

                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tên</th>
                                <th>Ưu tiên</th>
                                <th>Thời gian tạo</th>
                                <th>Thời gian sửa</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.exam_groups.map((exam_group, index) => (
                                    <ExamGroupItem index={index} key={index} data={exam_group} loadExamGroup={this.loadExamGroup} editExamGroup={this.editExamGroup} removeExamGroup={this.removeExamGroup} />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {this.state.loading ? <LoadingItem /> : null}

                <AddExamGroup ref={this.addExamGroupRef} loadExamGroup={this.loadExamGroup} subject_id={this.state.subject_id} />
                <EditExamGroup ref={this.editExamGroupRef} loadExamGroup={this.loadExamGroup} data={this.state.currentExamGroup} />
                <RemoveExamGroup ref={this.removeExamGroupRef} loadExamGroup={this.loadExamGroup} data={this.state.currentExamGroup} />
            </main>
        )
    }
}

export default withRouter(ManagerExamGroup);