import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../api/API';

import BannerItem from '../items/BannerItem';
import LoadingItem from '../items/LoadingItem';

import AddBanner from './banner/AddBanner';
import EditBanner from './banner/EditBanner';
import RemoveBanner from './banner/RemoveBanner';

class ManagerBanner extends Component {

    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            loading: true,
            banners: [],
            currentBanner: null
        }

        this.addBannerRef = React.createRef();
        this.editBannerRef = React.createRef();
        this.removeBannerRef = React.createRef();
        this.loadBanner = this.loadBanner.bind(this);
        this.editBanner = this.editBanner.bind(this);
        this.removeBanner = this.removeBanner.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadBanner();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBanner() {
        console.log('loadBanner');
        this.setState({
            loading: true,
            banners: []
        })

        API.getBanner().then(res => {
            let banners = res.data.data.banners;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    banners: banners
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                banners: []
            })
        })
    }

    editBanner(banner) {
        this.setState({
            currentBanner: banner
        }, () => {
            this.editBannerRef.current.initState();
            window['$']("#editModal").modal("show");
        })
    }

    removeBanner(banner) {
        this.setState({
            currentBanner: banner
        }, () => {
            window['$']("#removeModal").modal("show");
        })
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Banner</h1>
                </div>
                <button type="button" className="btn btn-success float-right" data-toggle="modal" data-target="#addModal">Thêm mới</button>
                <br></br>
                <br></br>

                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tên</th>
                                <th>Địa chỉ hình ảnh</th>
                                <th>Địa chỉ Web</th>
                                <th>Địa chỉ Android</th>
                                <th>Địa chỉ iOS</th>
                                <th>Ưu tiên</th>
                                <th>Thời gian tạo</th>
                                <th>Thời gian sửa</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.banners.map((banner, index) => (
                                    <BannerItem index={index} key={index} data={banner} loadBanner={this.loadBanner} editBanner={this.editBanner} removeBanner={this.removeBanner} />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {this.state.loading ? <LoadingItem /> : null}

                <AddBanner ref={this.addBannerRef} loadBanner={this.loadBanner} />
                <EditBanner ref={this.editBannerRef} loadBanner={this.loadBanner} data={this.state.currentBanner} />
                <RemoveBanner ref={this.removeBannerRef} loadBanner={this.loadBanner} data={this.state.currentBanner} />
            </main >
        )
    }
}

export default withRouter(ManagerBanner);