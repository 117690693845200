import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../api/API';

import LogSubmitItem from '../items/LogSubmitItem';
import LoadingItem from '../items/LoadingItem';
import PaginationItem from '../items/PaginationItem';

import ViewLogSubmit from './log_submit/ViewLogSubmit';

class ManagerLogSubmit extends Component {

    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            loading: true,
            log_submits: [],
            page: 0,
            pages: 0,
            pagination: 0,
            currentLogSubmit: null
        }

        this.viewLogSubmitRef = React.createRef();
        this.paginationRef = React.createRef();

        this.loadLogSubmit = this.loadLogSubmit.bind(this);
        this.viewLogSubmit = this.viewLogSubmit.bind(this);
        this.loadPage = this.loadPage.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadLogSubmit();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadPage(page) {
        this.loadLogSubmit({
            page: page
        })
    }

    loadLogSubmit(data) {
        this.setState({
            loading: true,
            log_submits: []
        })

        API.getLogSubmit(data).then(res => {
            let log_submits = res.data.data.log_submits;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    log_submits: log_submits,
                    page: res.data.data.page,
                    pages: res.data.data.pages,
                    pagination: res.data.data.pagination
                })
                this.paginationRef.current.initState();
            }
        }).catch(error => {
            this.setState({
                loading: false,
                log_submits: []
            })
        })
    }

    viewLogSubmit(logSubmit) {
        this.setState({
            currentLogSubmit: logSubmit
        }, () => {
            window['$']("#viewModal").modal("show");
        })
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Lịch sử làm đề</h1>
                </div>
                <br></br>

                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>ID</th>
                                <th>Người dùng</th>
                                <th>Đề thi</th>
                                <th>Số lượng câu hỏi</th>
                                <th>Số câu trả lời đúng</th>
                                <th>Thời gian tạo</th>
                                <th>Thời gian sửa</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.log_submits.map((log_submit, index) => (
                                    <LogSubmitItem index={index} key={index} data={log_submit} page={this.state.page} pagination={this.state.pagination} viewLogSubmit={this.viewLogSubmit} />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {this.state.loading ? <LoadingItem /> : null}
                {this.state.loading ? null : <PaginationItem ref={this.paginationRef} page={this.state.page} pages={this.state.pages} loadPage={this.loadPage} />}

                <ViewLogSubmit ref={this.viewLogSubmitRef} data={this.state.currentLogSubmit} />
            </main >
        )
    }
}

export default withRouter(ManagerLogSubmit);