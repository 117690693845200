import React, { Component } from 'react';

class SelectItem extends Component {

    constructor() {
        super();
        this.state = {
            value: ''
        }

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({
            value: e.target.value
        }, () => {
            this.props.onChange(this.state.value);
        })
    }

    getValue() {
        return this.state.value;
    }

    render() {
        return (
            <select className="form-control" onChange={this.onChange} >
                <option value={''} key={-1}>{this.props.default}</option>
                {
                    this.props.data.map((item, index) => (
                        <option value={item.id} key={index}>{item.name}</option>
                    ))
                }
            </select>
        )
    }
}

export default SelectItem;