import Constant from '../helper/Constant';
import jwtDecode from 'jwt-decode';

let Util = {

    getToken() {
        return localStorage.getItem(Constant.SAVE_TOKEN);
    },

    checkUserLogin() {
        let token = this.getToken();
        if (token && jwtDecode(token).exp > Date.now() / 1000) {
            return true;
        } else {
            return false;
        }
    }
}

export default Util;