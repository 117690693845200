import React, { Component } from 'react';

import FormatUtil from '../../util/FormatUtil';

class UserItem extends Component {

    constructor() {
        super();
        this.onView = this.onView.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
    }

    onView(e) {
        e.preventDefault();
        this.props.viewUser(this.props.data);
    }

    onChangePassword(e) {
        e.preventDefault();
        this.props.changePasswordUser(this.props.data);
    }

    render() {
        return (
            <tr>
                <td className="align-middle">{this.props.page * this.props.pagination + this.props.index + 1}</td>
                <td className="align-middle">{this.props.data.username}</td>
                <td className="align-middle">{this.props.data.name}</td>
                <td className="align-middle">{this.props.data.email}</td>
                <td className="align-middle">{this.props.data.phone}</td>
                <td className="align-middle">{FormatUtil.timeToTimeString(this.props.data.logged_at)}</td>
                <td className="align-middle">{FormatUtil.timeToTimeString(this.props.data.created_at)}</td>
                <td className="align-middle">{FormatUtil.timeToTimeString(this.props.data.updated_at)}</td>
                <td className="align-middle">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-primary mr-2" onClick={this.onChangePassword}>Đổi mật khẩu</button>
                        <button type="button" className="btn btn-info mr-2" onClick={this.onView}>Chi tiết</button>
                    </div>
                </td>
            </tr>
        )
    }
}

export default UserItem;