import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

class DashBoard extends Component {

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Trang chủ</h1>
                </div>
            </main>
        )
    }
}

export default withRouter(DashBoard);