import React, { Component } from 'react';
import AlertDanger from '../alert/AlertDanger';

import API from '../../api/API';
import APIConstant from '../../api/APIConstant';

import Message from '../../helper/Message';
import Constant from '../../helper/Constant';

import { withRouter } from 'react-router-dom';

class Login extends Component {

    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            isLogin: false,
            isError: false,
            error: ''
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit(e) {
        e.preventDefault();

        const data = {
            username: this.state.username,
            password: this.state.password
        }

        this.setState({
            isLogin: false,
            isError: false,
            error: ''
        });

        API.userLogin(data).then(res => {
            if (res.data.status === APIConstant.REQUEST_STATUS_SUCCESS) {
                this.setState({
                    isLogin: true,
                    isError: false,
                    error: ''
                });

                let token = res.data.data.data.token;
                localStorage.setItem(Constant.SAVE_TOKEN, token);
                this.props.history.push('/home');
            } else {
                this.setState({
                    isLogin: false,
                    isError: true,
                    error: res.data.message
                });
            }
        }).catch(error => {
            this.setState({
                isLogin: false,
                isError: true,
                error: Message.REQUEST_FAIL
            });
        });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mt-6 mx-auto">
                        <form noValidate onSubmit={this.onSubmit}>
                            <br />
                            <h1 className="h3 mb-3 font-weight-normal">Đăng nhập</h1>
                            {this.state.isError ? <AlertDanger message={this.state.error} /> : null}
                            <div className="form-group">
                                <input type="text" className="form-control" name="username" placeholder="Tên đăng nhập" value={this.state.username} onChange={this.onChange} />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" name="password" placeholder="Mật khẩu" value={this.state.password} onChange={this.onChange} />
                            </div>
                            <button type="submit" className="btn btn-lg btn-primary btn-block">
                                Đăng nhập
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Login);