import React, { Component } from 'react';

import FormatUtil from '../../../util/FormatUtil';
import Message from '../../../helper/Message';

import API from '../../../api/API';
import APIConstant from '../../../api/APIConstant';

class ChangePasswordAvatar extends Component {

    constructor() {
        super();
        this.state = {
            new_password: '',
            re_new_password: '',
            message: '',
            loading: false
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.state.new_password.length > 0 && this.state.re_new_password.length > 0) {
            if (this.state.new_password === this.state.re_new_password) {
                const data = {
                    id: this.props.data.id,
                    password: this.state.new_password,
                }

                if (!FormatUtil.checkObjectEmpty(data)) {
                    this.setState({
                        message: '',
                        loading: true
                    })

                    API.changePasswordUser(data).then(res => {
                        if (res.data.status === APIConstant.REQUEST_STATUS_SUCCESS) {
                            this.setState({
                                message: '',
                                loading: false
                            })
                            window['$']("#changePasswordModal").modal("hide");
                        } else {
                            this.setState({
                                message: res.data.message,
                                loading: false
                            })
                        }
                    }).catch(error => {
                        this.setState({
                            message: Message.REQUEST_FAIL,
                            loading: false
                        })
                        console.log(error);
                    })
                } else {
                    this.setState({
                        message: Message.DATA_EMPTY
                    })
                }
            } else {
                this.setState({
                    message: Message.RE_PASSWORD_NOT_VALID
                })
            }
        } else {
            this.setState({
                message: Message.DATA_EMPTY
            })
        }
    }

    render() {
        return (
            <div className="modal fade" id="changePasswordModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Đổi mật khẩu</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Mật khẩu mới" name="new_password" onChange={this.onChange} value={this.state.new_password} />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Mật khẩu xác nhận" name="re_new_password" onChange={this.onChange} value={this.state.re_new_password} />
                            </div>
                            {
                                this.state.message ? <div className="alert alert-danger" role="alert">{this.state.message}</div> : null
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Hủy</button>
                            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={this.state.loading}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChangePasswordAvatar;