import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../api/API';

import ClassItem from '../items/ClassItem';
import LoadingItem from '../items/LoadingItem';

import AddClass from './class/AddClass';
import EditClass from './class/EditClass';
import RemoveClass from './class/RemoveClass';

class ManagerClass extends Component {

    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            loading: true,
            classes: [],
            currentClass: null
        }

        this.addClassRef = React.createRef();
        this.editClassRef = React.createRef();
        this.removeClassRef = React.createRef();
        this.loadClass = this.loadClass.bind(this);
        this.editClass = this.editClass.bind(this);
        this.removeClass = this.removeClass.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadClass();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadClass() {
        this.setState({
            loading: true,
            classes: []
        })

        API.getClass().then(res => {
            let classes = res.data.data.classes;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    classes: classes
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                classes: []
            })
        })
    }

    editClass(itemClass) {
        this.setState({
            currentClass: itemClass
        }, () => {
            this.editClassRef.current.initState();
            window['$']("#editModal").modal("show");
        })
    }

    removeClass(itemClass) {
        this.setState({
            currentClass: itemClass
        }, () => {
            window['$']("#removeModal").modal("show");
        })
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Lớp học</h1>
                </div>
                <button type="button" className="btn btn-success float-right" data-toggle="modal" data-target="#addModal">Thêm mới</button>
                <br></br>
                <br></br>

                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tên</th>
                                <th>Ưu tiên</th>
                                <th>Thời gian tạo</th>
                                <th>Thời gian sửa</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.classes.map((itemClass, index) => (
                                    <ClassItem index={index} key={index} data={itemClass} loadClass={this.loadClass} editClass={this.editClass} removeClass={this.removeClass} />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {this.state.loading ? <LoadingItem /> : null}

                <AddClass ref={this.addClassRef} loadClass={this.loadClass} />
                <EditClass ref={this.editClassRef} loadClass={this.loadClass} data={this.state.currentClass} />
                <RemoveClass ref={this.removeClassRef} loadClass={this.loadClass} data={this.state.currentClass} />
            </main >
        )
    }
}

export default withRouter(ManagerClass);