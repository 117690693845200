import React, { Component } from 'react';

import Message from '../../../helper/Message';

import API from '../../../api/API';
import APIConstant from '../../../api/APIConstant';

class AddExam extends Component {

    constructor() {
        super();
        this.state = {
            name: '',
            chapter: '',
            lesson: '',
            note: '',
            message: '',
            loading: false
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit(e) {
        e.preventDefault();

        const data = {
            name: this.state.name,
            chapter: this.state.chapter,
            lesson: this.state.lesson,
            note: this.state.note,
            exam_group_id: this.props.exam_group_id
        }

        if (data.name !== '' && data.exam_group_id !== '') {
            this.setState({
                message: '',
                loading: true
            })

            API.addExam(data).then(res => {
                if (res.data.status === APIConstant.REQUEST_STATUS_SUCCESS) {
                    this.setState({
                        name: '',
                        chapter: '',
                        lesson: '',
                        note: '',
                        message: '',
                        loading: false
                    })
                    this.props.loadExam();
                    window['$']("#addModal").modal("hide");
                } else {
                    this.setState({
                        message: res.data.message,
                        loading: false
                    })
                }
            }).catch(error => {
                this.setState({
                    message: Message.REQUEST_FAIL,
                    loading: false
                })
            })
        } else {
            this.setState({
                message: Message.DATA_EMPTY
            })
        }
    }

    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Thêm mới đề</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Tên" name="name" onChange={this.onChange} value={this.state.name} />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Chương" name="chapter" onChange={this.onChange} value={this.state.chapter} />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Bài" name="lesson" onChange={this.onChange} value={this.state.lesson} />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Ghi chú" name="note" onChange={this.onChange} value={this.state.note} />
                            </div>
                            {
                                this.state.message ? <div className="alert alert-danger" role="alert">{this.state.message}</div> : null
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Hủy</button>
                            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={this.state.loading}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddExam;