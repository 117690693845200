import React, { Component } from 'react';

import FormatUtil from '../../../util/FormatUtil';

class ViewLogReport extends Component {

    render() {
        return (
            <div className="modal fade" id="viewModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Chi tiết lịch sử báo lỗi</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">ID:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.id : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Câu hỏi:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.question_id : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Nội dung:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.content : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Trạng thái:</div>
                                        <div className="col-sm-8">{this.props.data ? this.props.data.is_resolved ? 'Đã sửa' : 'Chưa sửa' : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Thời gian tạo:</div>
                                        <div className="col-sm-8">{this.props.data ? FormatUtil.timeToTimeString(this.props.data.created_at) : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 font-weight-bold">Thời gian sửa:</div>
                                        <div className="col-sm-8">{this.props.data ? FormatUtil.timeToTimeString(this.props.data.updated_at) : ''}</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Hủy</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewLogReport;