import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../api/API';

import AvatarItem from '../items/AvatarItem';
import LoadingItem from '../items/LoadingItem';

import AddAvatar from './avatar/AddAvatar';
import EditAvatar from './avatar/EditAvatar';
import RemoveAvatar from './avatar/RemoveAvatar';

class ManagerAvatar extends Component {

    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            loading: true,
            avatars: [],
            currentAvatar: null
        }

        this.addAvatarRef = React.createRef();
        this.editAvatarRef = React.createRef();
        this.removeAvatarRef = React.createRef();
        this.loadAvatar = this.loadAvatar.bind(this);
        this.editAvatar = this.editAvatar.bind(this);
        this.removeAvatar = this.removeAvatar.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadAvatar();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadAvatar() {
        this.setState({
            loading: true,
            avatars: []
        })

        API.getAvatar().then(res => {
            let avatars = res.data.data.avatars;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    avatars: avatars
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                avatars: []
            })
        })
    }

    editAvatar(avatar) {
        this.setState({
            currentAvatar: avatar
        }, () => {
            this.editAvatarRef.current.initState();
            window['$']("#editModal").modal("show");
        })
    }

    removeAvatar(avatar) {
        this.setState({
            currentAvatar: avatar
        }, () => {
            window['$']("#removeModal").modal("show");
        })
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Ảnh đại diện</h1>
                </div>
                <button type="button" className="btn btn-success float-right" data-toggle="modal" data-target="#addModal">Thêm mới</button>
                <br></br>
                <br></br>

                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tên</th>
                                <th>Địa chỉ</th>
                                <th>Ưu tiên</th>
                                <th>Thời gian tạo</th>
                                <th>Thời gian sửa</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.avatars.map((avatar, index) => (
                                    <AvatarItem index={index} key={index} data={avatar} loadAvatar={this.loadAvatar} editAvatar={this.editAvatar} removeAvatar={this.removeAvatar} />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {this.state.loading ? <LoadingItem /> : null}

                <AddAvatar ref={this.addAvatarRef} loadAvatar={this.loadAvatar} />
                <EditAvatar ref={this.editAvatarRef} loadAvatar={this.loadAvatar} data={this.state.currentAvatar} />
                <RemoveAvatar ref={this.removeAvatarRef} loadAvatar={this.loadAvatar} data={this.state.currentAvatar} />
            </main >
        )
    }
}

export default withRouter(ManagerAvatar);