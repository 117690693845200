import Constant from '../helper/Constant';
import Message from '../helper/Message';

let FormatUtil = {

    checkObjectEmpty(data) {
        for (const property in data) {
            if (data[property] === '') {
                return true;
            }
        }
        return false;
    },

    numberWithCommas(x) {
        if (x) {
            x = parseInt(x);
            let parts = x.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return parts.join('.');
        }
        return '0';
    },

    timeToTimeString(time) {
        var newDate = new Date(time);
        let hours = newDate.getHours();
        let minutes = newDate.getMinutes();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (date < 10) {
            date = '0' + date;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return hours + ':' + minutes + ' ' + date + '/' + month + '/' + year;
    },

    timeToDateString(time) {
        var newDate = new Date(time);
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        if (date < 10) {
            date = '0' + date;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return date + '/' + month + '/' + year;
    },

    getLoginTextByType(type) {
        switch (type) {
            case Constant.LOGIN_TYPE_ACCOUNT:
                return Message.LOGIN_TYPE_ACCOUNT;
            case Constant.LOGIN_TYPE_FACEBOOK:
                return Message.LOGIN_TYPE_FACEBOOK;
            case Constant.LOGIN_TYPE_GOOGLE:
                return Message.LOGIN_TYPE_GOOGLE;
            default:
                return type;
        }
    },

    getQuestionString: function (question) {
        let questionString = '';
        for (let i = 0; i < question.length; i++) {
            switch (i) {
                case 0:
                    questionString += 'A: ' + question[i] + ' ';
                    break;
                case 1:
                    questionString += 'B: ' + question[i] + ' ';
                    break;
                case 2:
                    questionString += 'C: ' + question[i] + ' ';
                    break;
                case 3:
                    questionString += 'D: ' + question[i] + ' ';
                    break;
                default:
                    break;
            }
        }
        return questionString;
    },

    getAnswerString: function (answer) {
        let answerString = '';
        for (let i = 0; i < answer.length; i++) {
            switch (answer[i]) {
                case 0:
                    answerString += ' A ';
                    break;
                case 1:
                    answerString += ' B ';
                    break;
                case 2:
                    answerString += ' C ';
                    break;
                case 3:
                    answerString += ' D ';
                    break;
                default:
                    break;
            }
        }
        return answerString;
    }
}

export default FormatUtil;