import React, { Component } from 'react';

class AddQuestionItem extends Component {

    constructor() {
        super();

        this.state = {
            question: '',
            is_correct: false
        }
        this.onChange = this.onChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    initData() {
        if (this.props.question) {
            this.setState({
                question: this.props.question,
                is_correct: this.props.is_correct
            })
        }
    }

    onInputChange(e) {
        this.setState({
            is_correct: e.target.checked
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getData() {
        return {
            question: this.state.question,
            is_correct: this.state.is_correct,
        }
    }

    reset() {
        this.setState({
            question: '',
            is_correct: false
        })
    }

    render() {
        return (
            <div className="form-group">
                <div className="row">
                    <div className="col-sm-1">
                        <input className="align-middle" type="checkbox" checked={this.state.is_correct} onChange={this.onInputChange} />
                    </div>
                    <div className="col-sm-11">
                        <input type="text" className="form-control" placeholder="Đáp án" name="question" onChange={this.onChange} value={this.state.question} />
                    </div>
                </div>
            </div >
        )
    }
}

export default AddQuestionItem;