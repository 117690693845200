import React, { Component } from 'react';

import FormatUtil from '../../util/FormatUtil';

class ConfigItem extends Component {

    constructor() {
        super();
        this.onRemove = this.onRemove.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    onRemove(e) {
        e.preventDefault();
        this.props.removeConfig(this.props.data);
    }

    onEdit(e) {
        e.preventDefault();
        this.props.editConfig(this.props.data);
    }

    render() {
        return (
            <tr>
                <td className="align-middle">{this.props.index + 1}</td>
                <td className="align-middle">{this.props.data.name}</td>
                <td className="align-middle">{this.props.data.data}</td>
                <td className="align-middle">{this.props.data.note}</td>
                <td className="align-middle">{FormatUtil.timeToTimeString(this.props.data.created_at)}</td>
                <td className="align-middle">{FormatUtil.timeToTimeString(this.props.data.updated_at)}</td>
                <td className="align-middle">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-danger mr-2" onClick={this.onRemove}>Xóa</button>
                        <button type="button" className="btn btn-primary mr-2" onClick={this.onEdit}>Sửa</button>
                    </div>
                </td>
            </tr>
        )
    }
}

export default ConfigItem;