import React, { Component } from 'react';

import Message from '../../../helper/Message';

import API from '../../../api/API';
import APIConstant from '../../../api/APIConstant';

class RemoveBanner extends Component {

    constructor() {
        super();
        this.state = {
            message: '',
            loading: false
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(e) {
        e.preventDefault();

        const data = {
            id: this.props.data.id,
        }

        this.setState({
            message: '',
            loading: true
        })

        API.removeBanner(data).then(res => {
            if (res.data.status === APIConstant.REQUEST_STATUS_SUCCESS) {
                this.setState({
                    message: '',
                    loading: false
                })
                this.props.loadBanner();
                window['$']("#removeModal").modal("hide");
            } else {
                this.setState({
                    message: res.data.message,
                    loading: false
                })
            }
        }).catch(error => {
            this.setState({
                message: Message.REQUEST_FAIL,
                loading: false
            })
        })
    }

    render() {
        return (
            <div className="modal fade" id="removeModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Thông báo</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <span>Bạn có chắc chắn muốn xóa dữ liệu này?</span>
                            {
                                this.state.message ? <div className="alert alert-danger" role="alert">{this.state.message}</div> : null
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Hủy</button>
                            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={this.state.loading}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RemoveBanner;