import React, { Component } from 'react';

import FormatUtil from '../../util/FormatUtil';

class LogSubmitItem extends Component {

    constructor() {
        super();
        this.onView = this.onView.bind(this);
    }

    onView(e) {
        e.preventDefault();
        this.props.viewLogSubmit(this.props.data);
    }

    render() {
        return (
            <tr>
                <td className="align-middle">{this.props.page * this.props.pagination + this.props.index + 1}</td>
                <td className="align-middle">{this.props.data.id}</td>
                <td className="align-middle">{this.props.data.user_id}</td>
                <td className="align-middle">{this.props.data.exam_id}</td>
                <td className="align-middle">{this.props.data.number_question}</td>
                <td className="align-middle">{this.props.data.number_question_correct}</td>
                <td className="align-middle">{FormatUtil.timeToTimeString(this.props.data.created_at)}</td>
                <td className="align-middle">{FormatUtil.timeToTimeString(this.props.data.updated_at)}</td>
                <td className="align-middle">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-info mr-2" onClick={this.onView}>Chi tiết</button>
                    </div>
                </td>
            </tr>
        )
    }
}

export default LogSubmitItem;