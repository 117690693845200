import React, { Component } from 'react';

import FormatUtil from '../../util/FormatUtil';

class LogDailyStatisticItem extends Component {

    onView(e) {
        e.preventDefault();
    }

    render() {
        return (
            <tr>
                <td className="align-middle">{this.props.page * this.props.pagination + this.props.index + 1}</td>
                <td className="align-middle">{FormatUtil.timeToDateString(this.props.data.updated_at)}</td>
                <td className="align-middle">{FormatUtil.numberWithCommas(this.props.data.number_register_user)}</td>
                <td className="align-middle">{FormatUtil.numberWithCommas(this.props.data.number_active_user)}</td>
                <td className="align-middle">{FormatUtil.numberWithCommas(this.props.data.number_pay_user)}</td>
                <td className="align-middle">{FormatUtil.numberWithCommas(this.props.data.total_pay)}</td>
                <td className="align-middle">{FormatUtil.numberWithCommas(this.props.data.number_get)}</td>
                <td className="align-middle">{FormatUtil.numberWithCommas(this.props.data.number_submit)}</td>
                <td className="align-middle">{FormatUtil.numberWithCommas(this.props.data.number_report)}</td>
                <td className="align-middle">{FormatUtil.numberWithCommas(this.props.data.number_time)}</td>
            </tr>
        )
    }
}

export default LogDailyStatisticItem;