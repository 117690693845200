import React, { Component } from 'react';

class PaginationItem extends Component {

    constructor() {
        super();
        this.state = {
            page: 0,
            pages: 0
        }
        this.loadPageClick = this.loadPageClick.bind(this);
    }

    initState() {
        this.setState({
            page: this.props.page,
            pages: this.props.pages
        })
    }

    loadPageClick(e, page) {
        e.preventDefault();
        if(page !== this.state.page) {
            this.props.loadPage(page);
        }
    }

    render() {
        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    <li className={this.state.page === 0 ? "page-item disabled" : "page-item"} >
                        <a className="page-link" href="!#" tabIndex="-1" onClick={(e) => this.loadPageClick(e, 0)}>Previous</a>
                    </li>
                    {
                        [...Array(this.state.pages)].map((x, i) =>
                            <li className={this.state.page === i ? "page-item active" : "page-item"} key={i}>
                                <a className="page-link" href="!#" onClick={(e) => this.loadPageClick(e, i)}>{i + 1}</a>
                            </li>
                        )
                    }
                    <li className={this.state.page === this.state.pages - 1 ? "page-item disabled" : "page-item"} >
                        <a className="page-link" href="!#" onClick={(e) => this.loadPageClick(e, this.state.pages - 1)}>Next</a>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default PaginationItem;