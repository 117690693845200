import React, { Component } from 'react';

import AddQuestionItem from '../../items/AddQuestionItem';
import UploadImageItem from '../../items/UploadImageItem';

import FormatUtil from '../../../util/FormatUtil';
import Message from '../../../helper/Message';

import API from '../../../api/API';
import APIConstant from '../../../api/APIConstant';

class AddQuestion extends Component {

    constructor() {
        super();
        this.state = {
            question: '',
            explain: '',
            number_upload_files: 0,
            message: '',
            loading: false
        }

        this.addQuestiontRef1 = React.createRef();
        this.addQuestiontRef2 = React.createRef();
        this.addQuestiontRef3 = React.createRef();
        this.addQuestiontRef4 = React.createRef();

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onAddUploadImage = this.onAddUploadImage.bind(this);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit(e) {
        e.preventDefault();

        const data = this.getData();

        if (!FormatUtil.checkObjectEmpty(data)) {
            this.setState({
                message: '',
                loading: true
            })

            API.addQuestion(data).then(res => {
                if (res.data.status === APIConstant.REQUEST_STATUS_SUCCESS) {
                    this.setState({
                        question: '',
                        explain: '',
                        message: '',
                        number_upload_files: 0,
                        loading: false
                    })

                    this.addQuestiontRef1.current.reset();
                    this.addQuestiontRef2.current.reset();
                    this.addQuestiontRef3.current.reset();
                    this.addQuestiontRef4.current.reset();
                    this.props.loadQuestion();
                    window['$']("#addModal").modal("hide");
                } else {
                    this.setState({
                        message: res.data.message,
                        loading: false
                    })
                }
            }).catch(error => {
                this.setState({
                    message: Message.REQUEST_FAIL,
                    loading: false
                })
            })
        } else {
            this.setState({
                message: Message.DATA_EMPTY
            })
        }
    }

    getData() {
        let choose1 = this.addQuestiontRef1.current.getData();
        let choose2 = this.addQuestiontRef2.current.getData();
        let choose3 = this.addQuestiontRef3.current.getData();
        let choose4 = this.addQuestiontRef4.current.getData();

        let chooses = [];
        let answer = [];
        if (choose1.question.length > 0) {
            chooses.push(choose1.question);
            if (choose1.is_correct) {
                answer.push(0);
            }
        }
        if (choose2.question.length > 0) {
            chooses.push(choose2.question);
            if (choose2.is_correct) {
                answer.push(1);
            }
        }
        if (choose3.question.length > 0) {
            chooses.push(choose3.question);
            if (choose3.is_correct) {
                answer.push(2);
            }
        }
        if (choose4.question.length > 0) {
            chooses.push(choose4.question);
            if (choose4.is_correct) {
                answer.push(3);
            }
        }

        const data = {
            question: this.state.question,
            explain: this.state.explain,
            chooses: chooses,
            answer: answer,
            exam_id: this.props.exam_id
        }

        if (data.explain === '') {
            data.explain = Message.NO;
        }

        return data;
    }

    onAddUploadImage(e) {
        e.preventDefault();
        this.setState({
            number_upload_files: this.state.number_upload_files + 1
        })
    }

    render() {
        return (
            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Thêm mới câu hỏi</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <textarea rows="5" className="form-control" placeholder="Câu hỏi" name="question" onChange={this.onChange} value={this.state.question} />
                            </div>
                            <AddQuestionItem ref={this.addQuestiontRef1} />
                            <AddQuestionItem ref={this.addQuestiontRef2} />
                            <AddQuestionItem ref={this.addQuestiontRef3} />
                            <AddQuestionItem ref={this.addQuestiontRef4} />
                            <button type="button" className="btn btn-success" onClick={this.onAddUploadImage} disabled={this.state.loading}>Thêm upload ảnh</button>
                            <br></br>
                            <br></br>
                            {
                                [...Array(this.state.number_upload_files)].map((x, i) =>
                                    <UploadImageItem key={i} />
                                )
                            }
                            <div className="form-group">
                                <textarea rows="5" className="form-control" placeholder="Giải thích" name="explain" onChange={this.onChange} value={this.state.explain} />
                            </div>
                            {
                                this.state.message ? <div className="alert alert-danger" role="alert">{this.state.message}</div> : null
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Hủy</button>
                            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={this.state.loading}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddQuestion;