let Constant = {

    SAVE_TOKEN: 'token',

    LOGIN_TYPE_ACCOUNT: 0,
    LOGIN_TYPE_FACEBOOK: 1,
    LOGIN_TYPE_GOOGLE: 2,

}

export default Constant;