import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import './App.css';

import Login from './components/login/Login';
import NavBar from './components/home/NavBar';
import SideBar from './components/home/SideBar';
import DashBoard from './components/home/DashBoard';
import ManagerConfig from './components/home/ManagerConfig';
import ManagerBanner from './components/home/ManagerBanner';
import ManagerAvatar from './components/home/ManagerAvatar';
import ManagerUser from './components/home/ManagerUser';
import ManagerClass from './components/home/ManagerClass';
import ManagerSubject from './components/home/ManagerSubject';
import ManagerExamGroup from './components/home/ManagerExamGroup';
import ManagerExam from './components/home/ManagerExam';
import ManagerQuestion from './components/home/ManagerQuestion';
import ManagerBookGuide from './components/home/ManagerBookGuide';

import ManagetLogDailyStatistic from './components/home/ManagetLogDailyStatistic';
import ManagerLogSubmit from './components/home/ManagerLogSubmit';
import ManagerLogReport from './components/home/ManagerLogReport';

import Util from './util/Util';

class App extends Component {

    constructor() {
        super();
        let isLogin = Util.checkUserLogin();
        this.state = {
            isLogin: isLogin
        }
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/">
                        {this.state.isLogin ? <Redirect to="/home" /> : <Redirect to="/login" />}
                    </Route>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/*">
                        <NavBar />
                        <div className="container-fluid">
                            <div className="row">
                                <SideBar />
                                <Route exact path="/home" component={DashBoard} />
                                <Route exact path="/config" component={ManagerConfig} />
                                <Route exact path="/banner" component={ManagerBanner} />
                                <Route exact path="/avatar" component={ManagerAvatar} />
                                <Route exact path="/user" component={ManagerUser} />
                                <Route exact path="/class" component={ManagerClass} />
                                <Route exact path="/subject" component={ManagerSubject} />
                                <Route exact path="/exam_group" component={ManagerExamGroup} />
                                <Route exact path="/exam" component={ManagerExam} />
                                <Route exact path="/question" component={ManagerQuestion} />
                                <Route exact path="/book_guide" component={ManagerBookGuide} />
                                <Route exact path="/log_daily_statistics" component={ManagetLogDailyStatistic} />
                                <Route exact path="/log_submit" component={ManagerLogSubmit} />
                                <Route exact path="/log_report" component={ManagerLogReport} />
                            </div>
                        </div>
                    </Route>
                </Switch>
            </Router>
        )
    }
}

export default App;