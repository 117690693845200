let Message = {

    REQUEST_FAIL: 'Có lỗi xảy ra trong quá trình thực hiện. Vui lòng kiểm tra lại.',
    DATA_EMPTY: 'Vui lòng điền đẩy đủ các thông tin',
    RE_PASSWORD_NOT_VALID: 'Mật khẩu xác nhận không chính xác',
    NO: 'Không',

    LOGIN_TYPE_ACCOUNT: 'Đăng nhập thường',
    LOGIN_TYPE_FACEBOOK: 'Đăng nhập Facebook',
    LOGIN_TYPE_GOOGLE: 'Đăng nhập Google',
}

export default Message;