import axios from 'axios';
import APIConstant from './APIConstant';
import Util from '../util/Util';

let API = {

    checkUserLogged: () => {
        return false;
    },

    userLogin: (data) => {
        return post(getUrl(APIConstant.API_LOGIN), data);
    },

    uploadImage: (data) => {
        return post(getUrl(APIConstant.API_UPLOAD_IMAGE), data);
    },

    getConfig: (data) => {
        return get(getUrl(APIConstant.API_GET_CONFIG), data);
    },

    addConfig: (data) => {
        return post(getUrl(APIConstant.API_ADD_CONFIG), data);
    },

    removeConfig: (data) => {
        return post(getUrl(APIConstant.API_REMOVE_CONFIG), data);
    },

    updateConfig: (data) => {
        return post(getUrl(APIConstant.API_UPDATE_CONFIG), data);
    },

    getBanner: (data) => {
        return get(getUrl(APIConstant.API_GET_BANNER), data);
    },

    addBanner: (data) => {
        return post(getUrl(APIConstant.API_ADD_BANNER), data);
    },

    removeBanner: (data) => {
        return post(getUrl(APIConstant.API_REMOVE_BANNER), data);
    },

    updateBanner: (data) => {
        return post(getUrl(APIConstant.API_UPDATE_BANNER), data);
    },

    getAvatar: (data) => {
        return get(getUrl(APIConstant.API_GET_AVATAR), data);
    },

    addAvatar: (data) => {
        return post(getUrl(APIConstant.API_ADD_AVATAR), data);
    },

    removeAvatar: (data) => {
        return post(getUrl(APIConstant.API_REMOVE_AVATAR), data);
    },

    updateAvatar: (data) => {
        return post(getUrl(APIConstant.API_UPDATE_AVATAR), data);
    },

    getUser: (data) => {
        return get(getUrl(APIConstant.API_GET_USER), data);
    },

    removeUser: (data) => {
        return post(getUrl(APIConstant.API_REMOVE_USER), data);
    },

    changePasswordUser: (data) => {
        return post(getUrl(APIConstant.API_CHANGE_PASSWORD_USER), data);
    },

    getClass: (data) => {
        return get(getUrl(APIConstant.API_GET_CLASS), data);
    },

    addClass: (data) => {
        return post(getUrl(APIConstant.API_ADD_CLASS), data);
    },

    removeClass: (data) => {
        return post(getUrl(APIConstant.API_REMOVE_CLASS), data);
    },

    updateClass: (data) => {
        return post(getUrl(APIConstant.API_UPDATE_CLASS), data);
    },

    getSubject: (data) => {
        return get(getUrl(APIConstant.API_GET_SUBJECT), data);
    },

    addSubject: (data) => {
        return post(getUrl(APIConstant.API_ADD_SUBJECT), data);
    },

    removeSubject: (data) => {
        return post(getUrl(APIConstant.API_REMOVE_SUBJECT), data);
    },

    updateSubject: (data) => {
        return post(getUrl(APIConstant.API_UPDATE_SUBJECT), data);
    },

    getExamGroup: (data) => {
        return get(getUrl(APIConstant.API_GET_EXAM_GROUP), data);
    },

    addExamGroup: (data) => {
        return post(getUrl(APIConstant.API_ADD_EXAM_GROUP), data);
    },

    removeExamGroup: (data) => {
        return post(getUrl(APIConstant.API_REMOVE_EXAM_GROUP), data);
    },

    updateExamGroup: (data) => {
        return post(getUrl(APIConstant.API_UPDATE_EXAM_GROUP), data);
    },

    getExam: (data) => {
        return get(getUrl(APIConstant.API_GET_EXAM), data);
    },

    addExam: (data) => {
        return post(getUrl(APIConstant.API_ADD_EXAM), data);
    },

    removeExam: (data) => {
        return post(getUrl(APIConstant.API_REMOVE_EXAM), data);
    },

    updateExam: (data) => {
        return post(getUrl(APIConstant.API_UPDATE_EXAM), data);
    },

    getQuestion: (data) => {
        return get(getUrl(APIConstant.API_GET_QUESTION), data);
    },

    addQuestion: (data) => {
        return post(getUrl(APIConstant.API_ADD_QUESTION), data);
    },

    removeQuestion: (data) => {
        return post(getUrl(APIConstant.API_REMOVE_QUESTION), data);
    },

    updateQuestion: (data) => {
        return post(getUrl(APIConstant.API_UPDATE_QUESTION), data);
    },

    getLogReport: (data) => {
        return get(getUrl(APIConstant.API_GET_LOG_REPORT), data);
    },

    updateLogReport: (data) => {
        return post(getUrl(APIConstant.API_UPDATE_LOG_REPORT), data);
    },

    getLogSubmit: (data) => {
        return get(getUrl(APIConstant.API_GET_LOG_SUBMIT), data);
    },

    getLogDailyStatistics: (data) => {
        return get(getUrl(APIConstant.API_GET_DAILY_STATISTICS), data);
    },

    reloadConfig: (data) => {
        return post(getUrl(APIConstant.API_RELOAD_CONFIG), data);
    }
}

let get = (url, data) => {
    let config = {
        method: 'get',
        url: url,
        headers: {
            'auth-token': Util.getToken()
        },
        params: data
    };

    return axios(config);
}

let post = (url, data) => {
    let config = {
        method: 'post',
        url: url,
        headers: {
            'auth-token': Util.getToken()
        },
        data: data
    };

    return axios(config);
}

let getUrl = (path) => {
    return APIConstant.API_URL + path;
}

export default API;