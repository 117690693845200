let APIConstant = {

    REQUEST_STATUS_SUCCESS: 1,
    REQUEST_STATUS_FAILURE: 0,

    // API_URL: 'http://localhost:5000/api/',
    // API_URL: 'http://103.226.250.175:3000/v1/',
    API_URL: 'http://api.tracnghiem247.info/v1/',
    API_LOGIN: 'admin/login',
    API_UPLOAD_IMAGE: 'admin/upload_image',
    API_GET_CONFIG: 'admin/configs',
    API_ADD_CONFIG: 'admin/config/add',
    API_REMOVE_CONFIG: 'admin/config/remove',
    API_UPDATE_CONFIG: 'admin/config/update',
    API_GET_BANNER: 'admin/banners',
    API_ADD_BANNER: 'admin/banner/add',
    API_REMOVE_BANNER: 'admin/banner/remove',
    API_UPDATE_BANNER: 'admin/banner/update',
    API_GET_AVATAR: 'admin/avatars',
    API_ADD_AVATAR: 'admin/avatar/add',
    API_REMOVE_AVATAR: 'admin/avatar/remove',
    API_UPDATE_AVATAR: 'admin/avatar/update',
    API_GET_USER: 'admin/users',
    API_REMOVE_USER: 'admin/user/remove',
    API_CHANGE_PASSWORD_USER: 'admin/user/change_password',
    API_GET_CLASS: 'admin/classes',
    API_ADD_CLASS: 'admin/class/add',
    API_REMOVE_CLASS: 'admin/class/remove',
    API_UPDATE_CLASS: 'admin/class/update',
    API_GET_SUBJECT: 'admin/subjects',
    API_ADD_SUBJECT: 'admin/subject/add',
    API_REMOVE_SUBJECT: 'admin/subject/remove',
    API_UPDATE_SUBJECT: 'admin/subject/update',
    API_GET_EXAM_GROUP: 'admin/exam_groups',
    API_ADD_EXAM_GROUP: 'admin/exam_group/add',
    API_REMOVE_EXAM_GROUP: 'admin/exam_group/remove',
    API_UPDATE_EXAM_GROUP: 'admin/exam_group/update',
    API_GET_EXAM: 'admin/exams',
    API_ADD_EXAM: 'admin/exam/add',
    API_REMOVE_EXAM: 'admin/exam/remove',
    API_UPDATE_EXAM: 'admin/exam/update',
    API_GET_QUESTION: 'admin/questions',
    API_ADD_QUESTION: 'admin/question/add',
    API_REMOVE_QUESTION: 'admin/question/remove',
    API_UPDATE_QUESTION: 'admin/question/update',
    API_GET_LOG_REPORT: 'admin/log_reports',
    API_UPDATE_LOG_REPORT: 'admin/log_report/update',
    API_GET_LOG_SUBMIT: 'admin/log_submits',
    API_GET_DAILY_STATISTICS: 'admin/daily_statistics',

    API_RELOAD_CONFIG: 'config/reload',
}

export default APIConstant;