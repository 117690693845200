import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../api/API';

import ExamItem from '../items/ExamItem';
import SelectItem from '../items/SelectItem';
import LoadingItem from '../items/LoadingItem';

import AddExam from './exam/AddExam';
import EditExam from './exam/EditExam';
import RemoveExam from './exam/RemoveExam';

class ManagerExam extends Component {

    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            loading: true,
            classes: [],
            class_id: '',
            subjects: [],
            subject_id: '',
            exam_groups: [],
            exam_group_id: '',
            exams: [],
            currentExam: null
        }

        this.addExamRef = React.createRef();
        this.editExamRef = React.createRef();
        this.removeExamRef = React.createRef();

        this.loadExam = this.loadExam.bind(this);
        this.editExam = this.editExam.bind(this);
        this.removeExam = this.removeExam.bind(this);

        this.onClassSelectChange = this.onClassSelectChange.bind(this);
        this.onSubjectSelectChange = this.onSubjectSelectChange.bind(this);
        this.onExamGroupSelectChange = this.onExamGroupSelectChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadClass();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadClass() {
        this.setState({
            loading: true,
            class_id: '',
            classes: [],
            subject_id: '',
            subjects: [],
            exam_group_id: '',
            exam_groups: [],
            exams: []
        })

        API.getClass().then(res => {
            let classes = res.data.data.classes;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    classes: classes
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                classes: []
            })
        })
    }

    loadSubject() {
        let data = {
            class_id: this.state.class_id
        }

        this.setState({
            loading: true,
            subject_id: '',
            subjects: [],
            exam_group_id: '',
            exam_groups: [],
            exams: []
        })

        API.getSubject(data).then(res => {
            let subjects = res.data.data.subjects;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    subjects: subjects
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                subjects: []
            })
        })
    }

    loadExamGroup() {
        let data = {
            subject_id: this.state.subject_id
        }

        this.setState({
            loading: true,
            exam_group_id: '',
            exam_groups: [],
            exams: []
        })

        API.getExamGroup(data).then(res => {
            let exam_groups = res.data.data.exam_groups;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    exam_groups: exam_groups
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                exam_groups: []
            })
        })
    }

    loadExam() {
        let data = {
            exam_group_id: this.state.exam_group_id
        }

        this.setState({
            loading: true,
            exams: []
        })

        API.getExam(data).then(res => {
            let exams = res.data.data.exams;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    exams: exams
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                exams: []
            })
        })
    }

    onClassSelectChange(id) {
        this.setState({
            class_id: id
        }, () => {
            this.loadSubject();
        })
    }

    onSubjectSelectChange(id) {
        this.setState({
            subject_id: id
        }, () => {
            this.loadExamGroup();
        })
    }

    onExamGroupSelectChange(id) {
        this.setState({
            exam_group_id: id
        }, () => {
            this.loadExam();
        })
    }

    editExam(itemClass) {
        this.setState({
            currentExam: itemClass
        }, () => {
            this.editExamRef.current.initState();
            window['$']("#editModal").modal("show");
        })
    }

    removeExam(itemClass) {
        this.setState({
            currentExam: itemClass
        }, () => {
            window['$']("#removeModal").modal("show");
        })
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Bộ đề</h1>
                </div>

                <SelectItem data={this.state.classes} default={'Chọn lớp học'} onChange={this.onClassSelectChange} />
                <br></br>
                <SelectItem data={this.state.subjects} default={'Chọn môn học'} onChange={this.onSubjectSelectChange} />
                <br></br>
                <SelectItem data={this.state.exam_groups} default={'Chọn bộ đề'} onChange={this.onExamGroupSelectChange} />
                <br></br>
                {
                    this.state.exam_group_id.length > 0 ? <button type="button" className="btn btn-success float-right" data-toggle="modal" data-target="#addModal">Thêm mới</button> : null
                }
                <br></br>
                <br></br>

                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tên</th>
                                <th>Chương</th>
                                <th>Bài</th>
                                <th>Ưu tiên</th>
                                <th>Thời gian tạo</th>
                                <th>Thời gian sửa</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.exams.map((exam, index) => (
                                    <ExamItem index={index} key={index} data={exam} loadExam={this.loadExam} editExam={this.editExam} removeExam={this.removeExam} />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {this.state.loading ? <LoadingItem /> : null}

                <AddExam ref={this.addExamRef} loadExam={this.loadExam} exam_group_id={this.state.exam_group_id} />
                <EditExam ref={this.editExamRef} loadExam={this.loadExam} data={this.state.currentExam} />
                <RemoveExam ref={this.removeExamRef} loadExam={this.loadExam} data={this.state.currentExam} />
            </main>
        )
    }
}

export default withRouter(ManagerExam);