import React, { Component } from 'react';

import { NavLink, withRouter } from 'react-router-dom';
import { Home, Users, Database, FileText, Settings, Image } from 'react-feather';

import API from '../../api/API';

class SideBar extends Component {

    constructor() {
        super();
        this.onReloadConfig = this.onReloadConfig.bind(this);
    }

    onReloadConfig(e) {
        e.preventDefault();

        API.reloadConfig().then(res => {
            alert(res.data.message);
        }).catch(error => {
            alert(error);
        })
    }

    render() {
        return (
            <nav className="col-md-2 d-none d-md-block bg-light sidebar">
                <div className="sidebar-sticky">
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span>Quản lý đề thi</span>
                    </h6>
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink to="/home" className="nav-link">
                                <Home className="feather" />
                                Trang chủ
                                <span className="sr-only">(current)</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/config" className="nav-link">
                                <Settings className="feather" />
                                Quản lý cấu hình
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/banner" className="nav-link">
                                <Image className="feather" />
                                Quản lý ảnh Banner
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/avatar" className="nav-link">
                                <Image className="feather" />
                                Quản lý ảnh đại diện
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/user" className="nav-link">
                                <Users className="feather" />
                                Quản lý người dùng
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/class" className="nav-link">
                                <Database className="feather" />
                                    Quản lý lớp học
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/subject" className="nav-link">
                                <Database className="feather" />
                                Quản lý môn học
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/exam_group" className="nav-link">
                                <Database className="feather" />
                                    Quản lý bộ đề
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/exam" className="nav-link">
                                <Database className="feather" />
                                    Quản lý đề
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/question" className="nav-link">
                                <Database className="feather" />
                                    Quản lý câu hỏi
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/book_guide" className="nav-link">
                                <Database className="feather" />
                                    Quản lý giải bài tập
                            </NavLink>
                        </li>
                    </ul>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span>Quản lý Lịch sử</span>
                    </h6>
                    <ul className="nav flex-column mb-2">
                        <li className="nav-item">
                            <NavLink to="/log_daily_statistics" className="nav-link">
                                <FileText className="feather" />
                                    Thống kê hàng ngày
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/log_submit" className="nav-link">
                                <FileText className="feather" />
                                    Lịch sử làm đề
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/log_report" className="nav-link">
                                <FileText className="feather" />
                                    Lịch sử báo lỗi
                            </NavLink>
                        </li>
                    </ul>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span>Khác</span>
                    </h6>
                    <ul className="nav flex-column mb-2">
                        <li className="nav-item">
                            <a className="nav-link" href="!#" onClick={this.onReloadConfig}>
                                <Settings className="feather" />
                                    Làm mới cấu hình
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}

export default withRouter(SideBar);