import React, { Component } from 'react';
import Constant from '../../helper/Constant';

import { withRouter, Redirect } from 'react-router-dom';
import Util from '../../util/Util';

class NavBar extends Component {

    constructor() {
        super();
        this.state = {
            isLogin: Util.checkUserLogin()
        }
        this.onLogOut = this.onLogOut.bind(this);
    }

    onLogOut(e) {
        e.preventDefault();
        localStorage.removeItem(Constant.SAVE_TOKEN);
        window.location.href = window.location.origin;
    }

    render() {
        return (
            <div>
                {this.state.isLogin ? null : (<Redirect push to="/" />)}
                <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0">
                    <span className="navbar-brand col-sm-3 col-md-2 mr-0">Ôn Thi Lớp 12</span>
                    <ul className="navbar-nav px-3">
                        <li className="nav-item text-nowrap">
                            <a className="nav-link" href="!#" onClick={this.onLogOut}>Đăng Xuất</a>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}

export default withRouter(NavBar);