import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../api/API';

import SubjectItem from '../items/SubjectItem';
import SelectItem from '../items/SelectItem';
import LoadingItem from '../items/LoadingItem';

import AddSubject from './subject/AddSubject';
import EditSubject from './subject/EditSubject';
import RemoveSubject from './subject/RemoveSubject';

class ManagerSubject extends Component {

    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            loading: true,
            classes: [],
            class_id: '',
            subjects: [],
            currentSubject: null
        }

        this.addSubjectRef = React.createRef();
        this.editSubjectRef = React.createRef();
        this.removeSubjectRef = React.createRef();

        this.loadSubject = this.loadSubject.bind(this);
        this.editSubject = this.editSubject.bind(this);
        this.removeSubject = this.removeSubject.bind(this);

        this.onClassSelectChange = this.onClassSelectChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadClass();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadClass() {
        this.setState({
            loading: true,
            class_id: '',
            classes: []
        })

        API.getClass().then(res => {
            let classes = res.data.data.classes;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    classes: classes
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                classes: []
            })
        })
    }

    loadSubject() {
        let data = {
            class_id: this.state.class_id
        }

        this.setState({
            loading: true,
            subjects: []
        })

        API.getSubject(data).then(res => {
            let subjects = res.data.data.subjects;
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    subjects: subjects
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                subjects: []
            })
        })
    }

    onClassSelectChange(id) {
        this.setState({
            class_id: id
        }, () => {
            this.loadSubject();
        })
    }

    editSubject(itemClass) {
        this.setState({
            currentSubject: itemClass
        }, () => {
            this.editSubjectRef.current.initState();
            window['$']("#editModal").modal("show");
        })
    }

    removeSubject(itemClass) {
        this.setState({
            currentSubject: itemClass
        }, () => {
            window['$']("#removeModal").modal("show");
        })
    }

    render() {
        return (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Môn học</h1>
                </div>

                <SelectItem data={this.state.classes} default={'Chọn lớp học'} onChange={this.onClassSelectChange} />
                <br></br>
                {
                    this.state.class_id.length > 0 ? <button type="button" className="btn btn-success float-right" data-toggle="modal" data-target="#addModal">Thêm mới</button> : null

                }
                <br></br>
                <br></br>

                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tên</th>
                                <th>Ưu tiên</th>
                                <th>Thời gian tạo</th>
                                <th>Thời gian sửa</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.subjects.map((subject, index) => (
                                    <SubjectItem index={index} key={index} data={subject} loadSubject={this.loadSubject} editSubject={this.editSubject} removeSubject={this.removeSubject} />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {this.state.loading ? <LoadingItem /> : null}

                <AddSubject ref={this.addSubjectRef} loadSubject={this.loadSubject} class_id={this.state.class_id} />
                <EditSubject ref={this.editSubjectRef} loadSubject={this.loadSubject} data={this.state.currentSubject} />
                <RemoveSubject ref={this.removeSubjectRef} loadSubject={this.loadSubject} data={this.state.currentSubject} />
            </main>
        )
    }
}

export default withRouter(ManagerSubject);