import React, { Component } from 'react';

import FormatUtil from '../../../util/FormatUtil';
import Message from '../../../helper/Message';

import API from '../../../api/API';
import APIConstant from '../../../api/APIConstant';

class EditAvatar extends Component {

    constructor() {
        super();
        this.state = {
            name: '',
            url: '',
            order: '',
            message: '',
            loading: false
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    initState() {
        this.setState({
            name: this.props.data.name,
            url: this.props.data.url,
            order: this.props.data.order,
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit(e) {
        e.preventDefault();

        const data = {
            id: this.props.data.id,
            name: this.state.name,
            url: this.state.url,
            order: this.state.order
        }

        if (!FormatUtil.checkObjectEmpty(data)) {
            this.setState({
                message: '',
                loading: true
            })

            API.updateAvatar(data).then(res => {
                if (res.data.status === APIConstant.REQUEST_STATUS_SUCCESS) {
                    this.setState({
                        message: '',
                        loading: false
                    })
                    this.props.loadAvatar();
                    window['$']("#editModal").modal("hide");
                } else {
                    this.setState({
                        message: res.data.message,
                        loading: false
                    })
                }
            }).catch(error => {
                this.setState({
                    message: Message.REQUEST_FAIL,
                    loading: false
                })
                console.log(error);
            })
        } else {
            this.setState({
                message: Message.DATA_EMPTY
            })
        }
    }

    render() {
        return (
            <div className="modal fade" id="editModal" tabIndex="-1" role="dialog"  aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Sửa ảnh đại diện</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Tên" name="name" onChange={this.onChange} value={this.state.name} />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Địa chỉ" name="url" onChange={this.onChange} value={this.state.url} />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Ưu tiên" name="order" onChange={this.onChange} value={this.state.order} />
                            </div>
                            {
                                this.state.message ? <div className="alert alert-danger" role="alert">{this.state.message}</div> : null
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Hủy</button>
                            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={this.state.loading}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditAvatar;